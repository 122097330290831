import React from 'react'
import Nullpage from '../components/nullPage/Nullpage'

const Setting = () => {
    return (
        <div className='h-full'>
            <Nullpage title="طراحی و پیاده سازی این بخش هنوز تکمیل نشده است" />
        </div>
    )
}

export default Setting